html, body {
    /* height: 100%; */
    font-family: 'Poppins', sans-serif !important;
}

.react-tel-input .form-control {
    font-size: 18px !important;
    font-family: 'Poppins', sans-serif !important;
}

p, h1, h2, h3, h4, h5, h6, button {
    margin: 0 !important;
    font-family: 'Poppins', sans-serif !important;
}

:root {
    --blue: #40B4D0;
    --white: #ffffff;
    --black: #000000;
    --yellow: #FFA224;
    --darkblue: #00527C;
    --red: #F00000;
}

/* @font-face {
    font-family: 'VerizonNHGDSBold';
    src: url('/public/assets/fonts/VerizonNHGDSBold.eot');
    src: url('/public/assets/fonts/VerizonNHGDSBold.eot') format('embedded-opentype'),
         url('/public/assets/fonts/VerizonNHGDSBold.woff2') format('woff2'),
         url('/public/assets/fonts/VerizonNHGDSBold.woff') format('woff'),
         url('/public/assets/fonts/VerizonNHGDSBold.ttf') format('truetype'),
         url('/public/assets/fonts/VerizonNHGDSBold.svg#VerizonNHGDSBold') format('svg');
}
@font-face {
    font-family: 'VerizonNHGDSBoldItalic';
    src: url('/public/assets/fonts/VerizonNHGDSBoldItalic.eot');
    src: url('/public/assets/fonts/VerizonNHGDSBoldItalic.eot') format('embedded-opentype'),
         url('/public/assets/fonts/VerizonNHGDSBoldItalic.woff2') format('woff2'),
         url('/public/assets/fonts/VerizonNHGDSBoldItalic.woff') format('woff'),
         url('/public/assets/fonts/VerizonNHGDSBoldItalic.ttf') format('truetype'),
         url('/public/assets/fonts/VerizonNHGDSBoldItalic.svg#VerizonNHGDSBoldItalic') format('svg');
}
@font-face {
    font-family: 'VerizonNHGDSItalic';
    src: url('/public/assets/fonts/VerizonNHGDSItalic.eot');
    src: url('/public/assets/fonts/VerizonNHGDSItalic.eot') format('embedded-opentype'),
         url('/public/assets/fonts/VerizonNHGDSItalic.woff2') format('woff2'),
         url('/public/assets/fonts/VerizonNHGDSItalic.woff') format('woff'),
         url('/public/assets/fonts/VerizonNHGDSItalic.ttf') format('truetype'),
         url('/public/assets/fonts/VerizonNHGDSItalic.svg#VerizonNHGDSItalic') format('svg');
}
@font-face {
    font-family: 'VerizonNHGDSRegular';
    src: url('/public/assets/fonts/VerizonNHGDSRegular.eot');
    src: url('/public/assets/fonts/VerizonNHGDSRegular.eot') format('embedded-opentype'),
         url('/public/assets/fonts/VerizonNHGDSRegular.woff2') format('woff2'),
         url('/public/assets/fonts/VerizonNHGDSRegular.woff') format('woff'),
         url('/public/assets/fonts/VerizonNHGDSRegular.ttf') format('truetype'),
         url('/public/assets/fonts/VerizonNHGDSRegular.svg#VerizonNHGDSRegular') format('svg');
}
@font-face {
    font-family: 'VerizonNHGTXBold';
    src: url('/public/assets/fonts/VerizonNHGTXBold.eot');
    src: url('/public/assets/fonts/VerizonNHGTXBold.eot') format('embedded-opentype'),
         url('/public/assets/fonts/VerizonNHGTXBold.woff2') format('woff2'),
         url('/public/assets/fonts/VerizonNHGTXBold.woff') format('woff'),
         url('/public/assets/fonts/VerizonNHGTXBold.ttf') format('truetype'),
         url('/public/assets/fonts/VerizonNHGTXBold.svg#VerizonNHGTXBold') format('svg');
}
@font-face {
    font-family: 'VerizonNHGTXBoldItalic';
    src: url('/public/assets/fonts/VerizonNHGTXBoldItalic.eot');
    src: url('/public/assets/fonts/VerizonNHGTXBoldItalic.eot') format('embedded-opentype'),
         url('/public/assets/fonts/VerizonNHGTXBoldItalic.woff2') format('woff2'),
         url('/public/assets/fonts/VerizonNHGTXBoldItalic.woff') format('woff'),
         url('/public/assets/fonts/VerizonNHGTXBoldItalic.ttf') format('truetype'),
         url('/public/assets/fonts/VerizonNHGTXBoldItalic.svg#VerizonNHGTXBoldItalic') format('svg');
}
@font-face {
    font-family: 'VerizonNHGTXItalic';
    src: url('/public/assets/fonts/VerizonNHGTXItalic.eot');
    src: url('/public/assets/fonts/VerizonNHGTXItalic.eot') format('embedded-opentype'),
         url('/public/assets/fonts/VerizonNHGTXItalic.woff2') format('woff2'),
         url('/public/assets/fonts/VerizonNHGTXItalic.woff') format('woff'),
         url('/public/assets/fonts/VerizonNHGTXItalic.ttf') format('truetype'),
         url('/public/assets/fonts/VerizonNHGTXItalic.svg#VerizonNHGTXItalic') format('svg');
}
@font-face {
    font-family: 'VerizonNHGTXRegular';
    src: url('/public/assets/fonts/VerizonNHGTXRegular.eot');
    src: url('/public/assets/fonts/VerizonNHGTXRegular.eot') format('embedded-opentype'),
         url('/public/assets/fonts/VerizonNHGTXRegular.woff2') format('woff2'),
         url('/public/assets/fonts/VerizonNHGTXRegular.woff') format('woff'),
         url('/public/assets/fonts/VerizonNHGTXRegular.ttf') format('truetype'),
         url('/public/assets/fonts/VerizonNHGTXRegular.svg#VerizonNHGTXRegular') format('svg');
} */

.MainWhole-wrapper-container-2 {
    width: 100%;
    background-color: #ffffff;
    margin: auto;
    height: 100vh !important;
    /* border: 2px solid var(--red) solid rgb(196 193 193); */
    border-top: 0px;
    /* height: 99vh; */
}

.MainWhole-wrapper-container {
    width: 100%;
    background-color: rgb(242, 242, 242);
    margin: auto;
    display: flex;
    flex-direction: column;
    border-top: 0px;
    height: 100vh;
}

.DesktopMain-wrapper {
    background-color: rgb(242, 242, 242);
    /* display: flex;
    align-items: center; */
}

.Login_page-wholeMain-wrapper {
    display: flex;
    align-items: center;
}

.DesktopMain-wrapper .Header-login-logout-btn {
    cursor: pointer;
    color: black;
    text-decoration: none;
    font-weight: 600;
}

.admin-header-acitve {
    color: rgb(47, 94, 164) !important;
}

.MainHeader-wrapper {
    background: white;
    left: 0;
    width: 100%;
}

.headerscroll {
    box-shadow: 0px 0px 10px #00000029;
}

.MainHeader-wrapper_copy {
    background: white;
    position: fixed;
    top: 0;
    z-index: 0 !important;
    left: 0;
    width: 100%;
}

.AdminHeader-right-side-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.AdminHeader-right-side-wrapper div {
    display: flex;
    flex-direction: column;
    margin: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    align-items: center;
}

.MainHeader-logo-div {
    /* width:50%; */
    text-align: start;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.MainHeader_second_div {
    display: grid;
    align-items: center;
    grid-template-columns: 39% 60%;
}

.MainHeader_second_div div {
    text-align: end;
}

.MainHeader_second_div h5 {
    font-weight: bold;
    font-size: 14px;
}

.MainHeader_second_div p {
    font-size: 14px;
}

.MainHeader_second_div svg {
    font-size: 46px;
    font-weight: 100;
    color: var(--red);
}

.MainHeader-wrapper.container {}

.MainHeader-logo-div-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    padding-left: 0px;
    width: 100%;
    margin: auto;
}

.MainHeader-logo-div p {
    /* position: absolute;
    left: 23px;
    top: 49px; */
}

.MainHeader-logo-div img {
    width: 55%;
}

.Main-Wrapper-Container, .ItemListMain-Wrapper, .CartPage-Main-wrapper {
    padding: 30px;
    position: relative;
    /* padding-bottom:10px; */
    padding-top: 100px;
}

.ItemListMain-Wrapper, .CartPage-Main-wrapper {
    padding-bottom: 30px;
}

.QR-Code-wrapper-whole {
    padding-bottom: 30px;
    text-align: center;
}

.QR-Code-wrapper-whole p {
    font-weight: bold;
    font-size: 17.9px;
}

.QR-Code-wrapper-whole-inner-one h3 {
    color: var(--red);
    font-size: 22px;
}

.Free-delivery-wrapper {
    text-align: left;
    background: linear-gradient(to right, rgb(244, 123, 38), rgb(238, 48, 108));
    width: 50%;
    border-radius: 0px 0px 10px 10px;
    padding: 17px;
    margin-left: 0px;
    margin-top: -10px;
    padding-top: 28px;
}



.product-images-class {
    width: 100%;
    /* border: 3px solid rgb(242,242,242); */
    height: auto;
}

.product-content-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 14px;
    width: 100%;
}

.product-content-wrapper-inner-div-one {
    width: 25%;
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 5px;
    background: #ffffff;
}

.product-content-wrapper-inner-div-two {
    width: 72%;
    text-align: left;
    margin-left: 32px;
}

.product-content-wrapper-inner-div-two h4 {
    font-size: 12px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 6px;
    margin-top: 0px;

}

.product-content-wrapper-inner-div-two p {
    margin: 6px;
    font-size: 11px;
}

.ViewAll-Items-wrapper button {
    background-color: rgb(50 89 149) !important;
    border: none !important;
    color: white !important;
    text-transform: capitalize !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    width: 95%;
    padding: 6px 9px;
    border-radius: 6px;
}

.ViewAll-Items-wrapper {
    margin-top: 15px;
}


/* ===================Landing_page_header_css=================================================================================================================================================================================================================================================== */

.Landing_page_Header_image_wrapper img {
    width: 44%;
    margin: 25px 0;
}

h1.HomePage-Main-Heading-text {
    width: 90%;
    margin: auto !important;
    font-size: 45px;
    line-height: 47px;
    font-weight: bold;
}

.HomePage-Main-Heading-text span {
    color: #EE0000;
}

.HomePage_slider_div-class {
    background-repeat: no-repeat;
    height: 244px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: baseline;
    background-size: 332px !important;
    background-position: right;
    margin-top: 65px;
}

.HomePage_slider_content_div_content {
    margin-bottom: 0rem;
    text-align: left;
    margin-left: 35px;
}

.HomePage_slider_content_div_content label {
    background-color: rgb(75, 79, 91);
    color: white;
    padding: 4px 13px;
    border-radius: 44px;
    text-transform: uppercase !important;
    font-weight: 600;
}

.HomePage_slider_content_div_content h4 {
    margin-top: 30px !important;
    font-size: 30px;
    width: 100%;
    line-height: 30px;
    text-align: center;
}

.owl-theme .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    top: 40px;
    width: 100%;

}

.HomePage-Main-Wrapper-Container {
    padding-bottom: 85px;
}

.HomePage-Main-Wrapper-Container .owl-dots {
    margin-top: 15px;
}

.HomePage-Main-Wrapper-Container .owl-theme .owl-dots .owl-dot span {
    width: 20px !important;
    height: 20px !important;
    background-color: #40B4D0;
    opacity: 0.1;
}

.HomePage-Main-Wrapper-Container button.owl-prev, .HomePage-Main-Wrapper-Container button.owl-next {
    font-size: 96px !important;
    font-weight: 200 !important;
    margin: 10px !important;
    color: var(--red) !important;
}

.HomePage-Main-Wrapper-Container button.owl-dot.active span {
    background-color: var(--red) !important;
    width: 13px !important;
    height: 13px !important;
    opacity: 1 !important;
}

.owl-carousel .owl-item img {
    width: 80% !important;
    margin: auto !important;
    filter: grayscale();
}

button.owl-prev {
    position: absolute;
    left: 10px;
}

button.owl-next {
    position: absolute !important;
    right: 10px !important;
}

.HomePage-Main-Wrapper-Container button.owl-prev:hover, .HomePage-Main-Wrapper-Container button.owl-next:hover {
    background-color: transparent !important;
}

.HomePage_order_now_button {
    background-color: var(--red);
    padding: 10px 43px;
    color: white;
    border: none !important;
    border-radius: 30px;
    font-size: 22px;
    font-weight: bold;
    margin: 20px !important;
    cursor: pointer;
    position: relative;
    z-index: 99999999 !important;
    margin-top: 25px !important;
}

.HomePage_section_two_wrapper img {
    width: 60%;
}

.HomePage_How_It_Works_text {
    font-size: 25px;
    margin-top: 25px !important;
    margin-bottom: 0px !important;
    color: var(--red);
    font-weight: bold;
}

/* ===================End=================================================================================================================================================================================================================================================== */
/* ===================HomePageSecond=================================================================================================================================================================================================================================================== */
.HomePage_How_It_Works_text_second {
    background-color: rgb(75, 79, 91);
    width: 21%;
    margin: auto !important;
    position: absolute;
    color: white !important;
    left: 36%;
    padding: 11px 25px;
    top: -5%;
    font-weight: 400;
    border-radius: 30px;
    font-size: 18px;
}

.HomePage_second_Youtube_content_wrapper {
    position: relative;
    margin-top: 39px;
    margin-bottom: 29px;
}

.HomePage_Youtube_Video {
    width: 100%;
    height: 350px !important;
}

.HomePage_order_now_button_second {
    background-color: rgb(97, 188, 71);
    padding: 7px 34px;
    color: white;
    border: none !important;
    border-radius: 30px;
    font-size: 19px;
    font-weight: 600;
    margin: 20px !important;
    position: absolute;
    right: 30%;
    bottom: -25px;
}

.ytp-chrome-top.ytp-show-cards-title {
    display: none !important;
}

/* ===================End=================================================================================================================================================================================================================================================== */
/* ===================Footer=================================================================================================================================================================================================================================================== */
.Footer_Content_wrapper {
    /* margin-top:5px;
    margin-bottom:20px; */
    position: fixed;
    bottom: 0px;
    box-shadow: 0px 0px 10px #00000029;
    display: flex;
    z-index: 99999999 !important;
    background-color: var(--white);
    left: 0;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 14px 7px;
}

.Footer_Content_wrapper_copy {
    position: fixed;
    bottom: 0px;
    box-shadow: 0px 0px 10px #00000029;
    display: flex;
    z-index: 0 !important;
    background-color: var(--white);
    left: 0;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 14px 7px;
}

.Footer_Content_wrapper p {
    font-weight: 500;
    margin-bottom: 0px !important;
    font-size: 15px;
    margin-right: 13px !important;
}

.Footer_Content_wrapper img {
    width: 36%;
    /* margin-bottom:20px; */
    height: 100%;

}

/* ===================End=================================================================================================================================================================================================================================================== */

/* ==============================ItemListPage====================================================================================================================================================================================================================================== */
.ItemList_page_Content_wrapper h1, .ItemList_page_Content_wrapper p {
    text-align: left;
    font-weight: bold;
    font-size: 30px;
}

.ItemList_page_Content_wrapper p {
    text-align: left;
    font-size: 15.9px;
}

.ItemList_Page_Logos_Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 16px 12px;
    border-radius: 10px;
}

.ItemList_Logo_div_one img {
    width: 60%;
}

.ItemList_Logo_div_two img {
    width: 100%;
}

.Logos_Below_div_wrapper {
    background-color: rgb(97, 188, 71);
    color: white;
    width: 70%;
    margin: auto;
    padding: 7px;
    border-radius: 0px 0px 10px 10px;
}

.Logos_Below_div_wrapper p {
    font-weight: 500;
    font-size: 13px;
}

.ItemListMain-Wrapper .HomePage-Shop-To-Gate-Wrapper {
    padding: 20px;
    /* margin: 19px; */
    /* margin-bottom:10px; */

}

.ItemList-Shop-To-Gate-Wrapper-div-two {
    text-align: left;
    padding-left: 30px;
}

.ItemList-Shop-To-Gate-Wrapper-div-two p {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    margin: 0;
}

.ItemList-Shop-To-Gate-Wrapper-div-one {
    /* padding-left: 20px; */
}

.ItemsListPage-ItemsList-containers {
    display: flex;
    justify-content: center;
    align-items: center;
    /*  padding: 9px 19px; */
    align-items: center;
}

.ItemsListPage-ItemsList-containers .product-images-class {
    width: 100%;
    /* border: 3px solid rgb(242,242,242); */
    height: auto;
}

.ItemsListPage-ItemsList-containers .product-content-wrapper-inner-div-two h4 {
    font-size: 13px;
    margin-bottom: 10px;
    line-height: 16px;
}

.ItemsListPage-ItemsList-containers .product-content-wrapper-inner-div-two p {
    margin: 6px;
    font-size: 12px;
}

.ItemsListPage-ItemsList-Counter-wrapper {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 66%;
    align-self: center;
}

.ItemsListPage-ItemsList-Counter-wrapper_Two {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    align-self: center;
    margin: auto !important;
}

.ItemList_Page_Next_button {
    background-color: var(--red) !important;
    color: white !important;
    border: none;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0%;
    width: 100%;
    padding: 10px 20px;
    font-size: 27px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}

.ItemsListPage-ItemsList-wrapper-whole {
    margin-top: 20px;
}

.ItemsListPage-ItemsList-wrapper-whole h2 {
    color: #EE0000;
    font-size: 25px !important;
    text-align: left !important;
    font-weight: 700 !important;
}

.ItemsListPage-ItemsList-Counter-input {
    background: transparent;
    border: none;
    width: 100%;
    color: rgb(17, 43, 82);
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    /* padding-left: 14px;
    padding-right:25px; */
}

.ItemsListPage-ItemsList-Counter-input:focus {
    outline: none;
}

.ItemsListPage-ItemsList-Counter-btn {
    background: #ffffff;
    border: none;
    padding: 0px 10px;
    font-weight: 800;
    font-size: 20px;

    color: var(--red);
}

.ItemsListPage-ItemsList-Counter-btn_Two {
    background: #ffffff;
    border: 1px solid rgb(221 219 219);
    padding: 0px 10px;
    font-weight: 600;
    font-size: 20px;
    color: rgb(17, 43, 82);
    border-radius: 10px;
}

.ItemListpage-ViewCart-wrapper {
    background-color: rgb(21, 64, 129);
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*padding:0px 34px;*/
    bottom: 0;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
}

.ItemList_page_Content_wrapper p {
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal !important;
}

.ItemListpage-ViewCart-wrapper p {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
    cursor: pointer;
    margin: 15px 25px;
}

.ItemListpage-ViewCart-wrapper-p-two {
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
    color: white !important;
    text-decoration: none !important;
}

.ItemListpage-ViewCart-wrapper-p-two a {
    Color: white !important;
    text-decoration: none !important;
}

.ItemListpage-ViewCart-wrapper-p-two span {
    margin-left: 10px;
}

.ItemList_Image_content {
    position: absolute;
    top: 10px;
    left: 20px;
    color: white;
    font-weight: bold;
}

.ItemList_page_Single_Item {
    max-width: unset !important;
    width: 47.5%;
    margin: 0px !important;
    margin-bottom: 20px !important;
}

.Item_Individual_Image {
    padding-top: 36px;
    padding-bottom: 10px;
}

.Item_Individual_btn_content {
    padding-bottom: 20px !important;
    padding: 10px !important;
}

.ItemList_Page_2_List_wrapper_Main_Whole {
    grid-column-gap: 15px;
}

/* ==============================End===================================================================================================================================================================================================================================== */
/* ==============================storelist page===================================================================================================================================================================================================================================== */
.Storelist-container-wrapper {
    margin-top: 24px;
}

.Storelist-container-wrapper h3 {
    text-align: left;
    font-size: 21px;
    font-weight: bold;
    color: var(--red)
}

.Storelist_container_inner_item_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.Storelist_container_inner_item_wrapper img {
    margin: 20px;
    width: 28%;
    margin-left: 0px;
}

.Storelist_container_inner_item_wrapper_content_div {
    text-align: left;
}

.Storelist_container_inner_item_wrapper_content_div h4 {
    font-size: 24px;
}

.Storelist_container_inner_item_wrapper_content_div h6 {
    font-size: 16px;
}

.Storelist_container_inner_item_wrapper_content_div h6:last-of-type {
    color: #40B4D0;
    font-size: 14px;
}

.Storelist_container_inner_item_wrapper_content_div p {
    font-size: 14px;
    width: 241px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================Productlist page===================================================================================================================================================================================================================================== */
.Product_page_outer_div {
    display: flex;
    justify-content: space-between;
    padding: 10px 34px 0px 20px;
}

.Product_page_inner_div_one {
    display: flex;
}

.Product_page_inner_div_one h5 {
    color: var(--red)
}

.Product_page_header_wrapper p {
    text-align: left;
    font-size: 13px;
    font-weight: normal;
    padding-left: 30px;

}

.Product_page_header_wrapper {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    margin-right: 63px;
    background-color: var(--white);
    z-index: 99999 !important;
    padding-bottom: 15px;
}

.Product_page_header_wrapper_two {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    margin-right: 63px;
    background-color: var(--white);
    z-index: 0 !important;
}

.Productlist_page_content_wrapper {
    padding-top: 111px;
    padding-bottom: 100px;
}

.Productlist_page_menu_wrapper {
    padding: 17px 28px;
}

.Productlist_page_menu_wrapper h3 {
    margin-bottom: 20px !important;
}

.Productlist_page_individual_product {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Productlist_page_productlist_wrapper {
    padding: 14px 16px;
}

.Productlist_page_individual_product_content_wrapper {
    text-align: left;
    margin: 15px;
    width: 90%;
}

.Productlist_page_individual_product_content_wrapper p {
    font-size: 11px;
    color: #777777;
}

.Productlist_page_individual_product_content_wrapper h2 {
    font-size: 20px;
    line-height: 24px;
}

.Productlist_page_individual_product_image_wrapper {
    box-shadow: 0px 0px 6px #0000001a;
    border-radius: 10px;
    width: 90%;
}

.Productlist_page_rating_div {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
    justify-content: flex-start;
    margin-top: 0px;
}

.Productlist_product_counter_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 64%;
    box-shadow: 0px 0px 6px #0000001a;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    margin-bottom: -10px !important;
    background: white;
    margin: auto;
}

.Productlist_page_menu_icon_wrapper {
    background-color: var(--white);
    color: var(--red);
    position: fixed;
    bottom: 76px;
    right: 33px;
    border-radius: 100%;
    padding: 25px 25px;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 99999 !important;
    box-shadow: 0px 0px 10px #00000046;
}

.Productlist_product_counter_wrapper button {
    width: 100%;
    background: transparent;
    border: none;
    color: var(--red);
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    font-size: 18px;
}

.ProductList_page_next_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #5FB4D0;
    /* padding: 10px 20px; */
    color: white;
    font-size: 27px;
    text-transform: capitalize !important;
    font-weight: bold;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
}

.ProductList_page_next_button_wrapper_inner {
    position: relative;
    width: 100%;
    padding: 10px 20px;
}

.Sorry_div_wrapper {
    text-align: center;
    padding: 20px 20px;
}

.Sorry_div_wrapper h4 {
    color: #D73B1E;
    font-size: 30px;
}

.Sorry_div_wrapper_p {
    font-size: 20px;
}

.Sorry_div_wrapper_inner_div {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-top: 0px;
    padding-bottom: 32px;
    cursor: pointer;
}

/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================ItemListTwo===================================================================================================================================================================================================================================== */
.ItemList_Page_2_List_wrapper_Main_Whole {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================DetailsPage===================================================================================================================================================================================================================================== */
.DetailsPage-Main-wrapper {
    /* margin-top: 24px; */
    padding: 25px;
    padding-top: 100px;
}

.DetailsPage-content-wrapper {
    /* background: #fff; */
    /* padding: 20px; */
    /* margin: 32px; */
    border-radius: 14px;
    margin-top: 17px;
}

.DetailsPage-content-wrapper .Mui-selected::after {
    content: "";
    border: none !important;
}

.DetailsPage-content-wrapper .css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root {
    border: 2px solid var(--red) solid rgb(221 219 219) !important;
    border-radius: 30px !important;
    padding: 22px 9px;
    margin: 4px !important;
    min-width: 65px !important;
}

.DetailsPage-content-wrapper .css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected {
    background-color: rgb(97, 188, 71);
    border-radius: 30px;
    padding: 0px 10px;
    letter-spacing: 1px;
    font-family: 'Poppins', sans-serif !important;
    color: white;
}

.DetailsPage-content-wrapper-inner {
    /* margin-top: 27px; */
    margin-bottom: 10px;
}

.DetailsPage-content-wrapper .css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
    text-align: left !important;
    font-weight: 900;
    font-size: 17px;
    color: rgb(97, 188, 71);
    /* padding-left:20px; */
}

.DetailsPage-Main-wrapper h2 {
    text-align: left;
    /* padding-left:33px; */
    margin-bottom: 0px;
    font-size: 20px;
}

.Details-page-Nextbutton-wrapper button {
    background-color: var(--red);
    color: white;
    padding: 13px 17px;
    border: none;
    width: 100%;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
}

.Details-page-Nextbutton-wrapper {
    /* margin-top: 12rem; */
    bottom: 0;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
}

.Details_page_disabled_button {
    background-color: #939393;
    color: white;
    padding: 18px 27px;
    font-weight: bold;
    font-size: 19px;
}

.DetailsPage_DeliveryLocation_Wrapper {
    display: flex;
    flex-wrap: wrap !important;
}

.detailspage-content-label {
    text-align: left !important;
    font-weight: 700 !important;
    font-size: 17px !important;
    color: #EE0000 !important;
    font-family: 'Poppins', sans-serif !important;
}

.Details_page_selected_deivery_location_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F5F5F5;
    padding: 17px;
    position: absolute;
    bottom: 73px;
    width: 80%;
    padding: 5%;
    left: 5%;
    border-radius: 5px;
}

.Details_page_selected_deivery_location_wrapper_cartpage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F5F5F5;
    padding: 17px;
    position: absolute;
    bottom: 28px;
    width: 80%;
    padding: 5%;
    left: 5%;
    border-radius: 5px;
}

.Details_page_selected_deivery_location_wrapper p {
    color: var(--red) !important;
    font-weight: bold;
    font-size: 20px;
}

/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================CartPage===================================================================================================================================================================================================================================== */

.CartPage-Main-wrapper .DetailsPage-content-wrapper-inner:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.CartPage-Main-wrapper .DetailsPage-content-wrapper-inner div {
    text-align: left;
    width: 100%;
    padding: 0;
    margin-top: 3px;
    justify-content: center;
    margin-left: 12px;
}

.CartPage-Main-wrapper .detailspage-content-label {

    font-size: 13px;
    text-align: left !important;
}

.CartPage-Main-wrapper .DetailsPage-content-wrapper-inner p {
    margin: 0px;
    margin-top: 6px;
    /* padding-left:20px; */
}

.CartPage-Main-wrapper h2 {
    text-align: start;
    /* padding-left: 33px; */
    margin-bottom: 0px;
    font-size: 23px;
    font-weight: bold;
}

.CartPage-Main-wrapper p {
    font-size: 20px;
    text-align: start;
    font-weight: bold;
    color: var(--red)
}

.CartPage-Main-wrapper {
    /* margin-top: 36px; */
    height: 77vh;
}

.CartPage-Main-wrapper .ItemsListPage-ItemsList-Counter-wrapper {
    display: flex;
    justify-content: end !important;
    align-items: center;
    width: 100%;
    margin-top: 17px;
    margin-left: 5rem;

}

.CartPage-Main-wrapper .product-content-wrapper-inner-div-one {
    width: 130px;
}

.CartPage-Main-wrapper .ItemsListPage-ItemsList-containers .product-images-class {
    width: 100%;
    /* border: 3px solid rgb(242,242,242); */
    height: auto;
}

.CartPage-Main-wrapper .product-content-wrapper {
    width: 50%;
}

.CartPage-Main-wrapper .CartPage-Product-wrapper-h2 {
    /* padding-left: 11px; */
    font-size: 18px;
    text-align: left;
}

.CartPage-Product-wrapper-h2 {
    color: var(--red);

}

.CartPage-amount-padi-wrapper {
    background: rgb(97, 188, 71);
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 13px 19px;
    /*   margin: 32px; */
    margin-top: 0px;
    border-radius: 0px 0px 14px 13px;
}

.CartPage-Main-wrapper .DetailsPage-content-wrapper {
    background: #fff;
    padding: 20px;
    margin: 0;
    border-radius: 10px;
    margin-top: 17px;
    margin-bottom: 0px;
}

.CartPage-content-wrapper {
    padding: 20px;
    /* margin:20px; */
}

.CartPage-amount-padi-wrapper p {
    margin: 6px;
    font-size: 17px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 300;
}

.CartPage-amount-padi-wrapper h4 {
    margin: 0px
}

.CartPage-Main-wrapper .ItemsListPage-ItemsList-containers .product-content-wrapper-inner-div-two p {
    font-size: 16px;
}

.Cart_page_Resend_button_wrapper {
    text-align: center;
    margin-top: 32px;
}

.Cart_page_Resend_button_wrapper p {
    margin: 11px !important;
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 20px;
}

.Cart_page_Resend_button_wrapper button {
    border: none;
    background-color: transparent;
    color: var(--red);
    border-radius: 0px;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid var(--red);
    text-transform: uppercase !important;
}

.Cart_page_otp_input {
    justify-content: center;
    margin-top: 22px;
}

.Cart_page_otp_input input {
    width: 35px !important;
    height: 35px !important;
    border: 2px solid #dddddd;
    border-radius: 10px;
    font-weight: bold;
    font-size: 22px;
}

.Cart_page_selected_deivery_location_wrapper {
    text-align: center !important;
    margin-top: 30px;
}

.Cart_page_selected_deivery_location_wrapper span {
    color: #EE0000;
}

/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================Congratulation page===================================================================================================================================================================================================================================== */

.Congratulation-content-div-one-wrapper {
    /* background-color: rgb(119,162,47); */
    background-color: #F5F5F5;
    color: black;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 10px 20px;
    margin-top: 0px;
}

.Congratulation-Main-wrapper_p {
    font-weight: bold;
    font-size: 19px;
}

.Congratulation-Main-wrapper_inner {
    /* padding: 20px; */
}

.congratulations_button_wrapper {
    margin-top: 15px;
}

.Congratulation-Main-wrapper_p span {
    color: #E51737;
}

.Modal_Header {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

.Modal_Header h3 {
    font-weight: bold;
}

.Modal_body {
    border: 1px solid lightgrey;
    border-radius: 0px 0px 0px 8px;
}

.Modal_body>div:nth-child(1) {
    border-right: 1px solid lightgrey;
    border-radius: 0px 0px 0px 8px;
    width: 50%;
    padding: 25px 15px;
    cursor: pointer;
}

.Modal_body>div:nth-child(2) {
    border-left: 1px solid lightgrey;
    border-radius: 0px 0px 0px 8px;
    width: 50%;
    padding: 25px 15px;
    cursor: pointer;
}

.Modal_body>div:nth-child(1) svg, .Modal_body>div:nth-child(2) svg {
    font-size: 60px;
    font-weight: 300;
}

.Modal_body>div:nth-child(1) p {
    font-weight: bold;
}

.Modal_body>div:nth-child(2) a {
    text-decoration: none;
    color: var(--black);
    font-weight: bold;
}

.Intrusction_modal {
    padding: 10px;
    overflow-y: scroll;
    height: 520px;
}

.Modal_Header button {
    color: var(--blue);
    font-weight: bold;
}

.Modal_video {
    border-radius: 0px 0px 8px 8px;
}

.congratulations_page_instruction_btn {
    /* padding: 6px 30px; */
    color: white;
}

.Congratulation-content-div-one-wrapper-inner-two {
    text-align: left;
    padding-left: 25px;
}

.Congratulation-content-div-one-wrapper-inner-two h1 {
    font-size: 34px;
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    text-align: start;
}

.Congratulation-content-div-one-wrapper-inner-two p {
    font-size: 20px;
    margin: 0px;
    font-weight: normal;
    width: 51% !important;
    color: var(--black);
    font-weight: bold;
    line-height: 25px;
}

.Congratulation-content-div-one-wrapper-inner-one {
    border-radius: 100%;
    width: 60px;
    height: 60px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.Congratulation-content-div-one-wrapper-inner-one img {
    /* width: 100%; */
    height: 40px;
    /* border-radius: 100%;
    object-fit: none;
    padding: 0px;
    margin-top: 0px;
    margin-left: 0px; */
}

.Congratulation-page-order-wrapper-below-QR {
    display: flex;
    align-items: center;
    /* padding: 10px 0px; */
    margin: 0px 25px;
    justify-content: center;
    border-bottom: 3px solid var(--blue);
    padding-bottom: 19px;
}

.Congratulation-page-order-wrapper-below-QR h5 {
    margin: 0px;
    color: var(--blue);
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
    font-size: 16px;
}

.Congratulations_page_QRcode_pin_wrapper {
    /* margin-top: 23px; */
}

.Congratulation-page-order-wrapper-below-QR p {
    margin: 0px;
    font-weight: 600;
    font-size: 13px;
    text-align: center !important;
}

.QR-Code-wrapper-whole-inner-one h4 {
    font-size: 18px;
    margin: 0px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
    line-height: 18px;
}

.QR-Code-wrapper-whole-inner-one p {
    font-size: 15px;
    line-height: 20px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
}

.QR-Code-wrapper-whole-inner-one {
    padding: 0px 26px;
    margin-bottom: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Congratulation-page-invoice-wrapper-table-whole {
    width: 92%;
    margin: 20px;
    border-collapse: collapse;
    margin-top: 5px;
    border-bottom: 1px solid lightgrey;
}

.readonly-input {
    pointer-events: none;
    /* Disable mouse and keyboard interactions */
    background-color: #f7f7f7;
    /* Optional: Set a background color for visual indication */
}

.Congratulation-page-invoice-wrapper-table-whole thead {
    border-bottom: 2px solid var(--red) solid rgb(97, 188, 71) !important;
    color: rgb(97, 188, 71) !important;
    padding-bottom: 20px !important;
}

.QR-Code-main-inner img {
    width: 300px !important;
    height: 300px !important;
    background: #FFF;
    padding: 10px;
    margin-top: 19px;
}

.Congratulation-page-invoice-wrapper-table-whole tbody tr td {
    padding: 9px;
    padding-left: 0px;
    font-size: 13px;
    font-weight: 600;
}

.Congratulation-page-invoice-wrapper-table-whole tbody tr td span {
    font-weight: bolder;
}

.Congratulation-page-subtotal-wrapper, .Congratulation-page-total-wrapper {
    text-align: right;

}

.Congratulation-page-subtotal-wrapper {
    margin: 30px;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.Congratulation-page-subtotal-wrapper h4 {
    margin: 4px;
}

.Congratulation-page-subtotal-wrapper h4 span {
    color: black;
    font-weight: 500;
}

.Congratulation-page-total-wrapper {
    margin: 30px;
    /* background: #DCE1E7; */
    font-weight: 700;
    color: var(--red) !important;

    margin-top: 0px;
}

.Congratulation-page-total-wrapper p {
    margin: 0px;
}

.Congratulation-page-total-wrapper p span {
    font-weight: 500;
}

.Congratulations_page_QRcode_pin_wrapper h3 {
    font-size: 18px;
    font-weight: bold;
}

.Congratulations_page_QRcode_pin_inner {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 14px;
    padding: 0px 28px;
}

.Congratulations_page_QRcode_pin_inner button {
    background: var(--red);
    color: white;
    border: none;
    border-radius: 50px;
    padding: 9px 27px;
    font-size: 15px;
    font-weight: bold;
}

.Congratulations_page_QRcode_pin_inner p {
    color: var(--black);
    font-weight: bold;
}

.Congratulation-page-invoice-wrapper {
    margin-top: 33px;
}

.Congratulation-page-invoice-wrapper h3 {
    margin: 20px !important;
    text-align: start;
    margin-bottom: 0px !important;
    color: var(--red)
}

.Toastify__toast-container--top-right {
    z-index: 9999999999999999999 !important;
}

.Congralutions_page_map_div {
    background-color: #F5F5F5;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    height: 50%;
    /* margin-top: 15px; */
}

.Congralutions_page_map_div h3 {
    opacity: 0.3;
}

.Congratulations_page_estimated_time_div p {
    font-weight: bold;
    font-size: 15px;
    /* margin-left: 18px !important; */
    text-align: start;
}

.Congratulations_page_estimated_time_div {
    display: grid;
    grid-template-columns: 60% 40%;
    align-items: center;
    color: #fff;
    margin-top: 0px !important;
    margin-right: 0px !important;
    position: fixed;
    bottom: 50px;
    background-color: var(--red);
    z-index: 999999999 !important;
    width: 100%;
    height: 65px;
    padding: 13px 25px;
}

.Congratulations_page_estimated_time_div h3 {
    background-color: var(--white);
    color: var(--red);
    font-weight: bold;
    /* padding: 16px 34px; */
    font-size: 20px;
    padding: 10px;
    border-radius: 7px;
}

button.Congratulations_page_QRcode_disabled_button {
    background-color: #BFBFBF;
}

.Congratulation_page_otp_input {
    justify-content: center;
    margin-top: 20px;
}

.Congratulation_page_otp_input input {
    border-radius: 5px;
    font-weight: 800;
    border: 1px solid grey;
}

/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================Login===================================================================================================================================================================================================================================== */
.Login-page-form {
    width: 25%;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 4px 33px 12px #d6d6d6;
    padding: 25px;
    padding-top: 37px;
    border-radius: 8px;
}

.LoginPage-Main-wrapper {
    height: 700px;
    align-items: center;
    align-self: center;
    vertical-align: middle;
    background-color: white;
    display: flex;
    width: 100%;
}

.Login-page-form .css-1a1fmpi-MuiInputBase-root-MuiInput-root {
    width: 95%;
    margin: auto;
}

.Login-page-form-logo-image {
    width: 48%;
}

.Loginpage-main-form-inner-div {
    margin-top: 25px !important;
}

.Login-page-form p {
    margin: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    color: rgb(21, 64, 129);
    font-weight: 600;
    margin-top: 4px !important;
}

.Login-page-form h6 {
    margin: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 400;
    font-size: 15px;
}

.Loginpage\=-main-form {
    margin: 24px;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 13px;
    margin-top: 29px;
}

.Login-page-form .css-1x51dt5-MuiInputBase-input-MuiInput-input {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: '#a89f9f' !important;
    letter-spacing: 0px !important;
}

.Login-page-form .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    color: black;
    font-weight: 500;
}

.Loginpage-btn-wrapper {
    background-color: rgb(45 87 152);
    padding: 10px;
    border-radius: 7px;
}

.Login-page-form button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.Loginpage-btn-wrapper-btn.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    color: #ffffff !important;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif !important;
    width: 100%;
}

.Login-page-form .css-1d1r5q-MuiFormHelperText-root.Mui-error {
    font-weight: 400;
    font-size: 10px;
    padding-left: 39px;
}

/* ==============================End===================================================================================================================================================================================================================================== */
/* ==============================Footer===================================================================================================================================================================================================================================== */
.Footer_Content_wrapper {
    position: fixed;
    bottom: 0px;
    box-shadow: 0px 0px 10px #00000029;
    display: flex;
    z-index: 99999999 !important;
    background-color: var(--white);
    left: 0;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 14px 7px;
}

.Footer_Content_wrapper_copy {
    position: fixed;
    bottom: 0px;
    box-shadow: 0px 0px 10px #00000029;
    display: flex;
    z-index: 0 !important;
    background-color: var(--white);
    left: 0;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 14px 7px;
}

.Footer_Content_wrapper p {
    font-weight: 500;
    margin-bottom: 0px !important;
    font-size: 15px;
    margin-right: 13px !important;
}

.Footer_Content_wrapper img {
    width: auto;
    height: 20px;
}

/* ==============================End===================================================================================================================================================================================================================================== */
/* ==============================Checkout===================================================================================================================================================================================================================================== */
.Checkout-StripeMain-wrapper {
    padding: 30px;
    height: 580px;
    padding-top: 92px;
}

.Checkout-StripeMain-wrapper h1 {
    text-align: left;
    color: var(--red);
    font-size: 61px;
    margin: 0px;
    letter-spacing: -2px solid var(--red) !important;
    /* padding-left: 11px; */
    font-family: 'Poppins', sans-serif;
}

.Checkout-StripeMain-wrapper h3 {
    text-align: left;
    /* padding-left: 18px; */
    margin-top: 4px;
}

.Checkout-StripeMain-wrapper .FormGroup {
    background: transparent;
    box-shadow: none;
    padding: 5px;
    border: 1px solid black;
}

.Checkout-StripeMain-wrapper .FormRow {
    border-top: none !important;
}

.CheckoutStripe-content-wrapper {
    background-color: #ffffff !important;
    padding: 20px !important;
}

/* ==============================End===================================================================================================================================================================================================================================== */
/* =================================SelectDeliveryLocation================================================================================================================================================================================================================================== */
.Select_delivery_page_inner_wrapper {
    text-align: left;
    padding-top: 15px;
    /* padding:33px; */
}

.Select_delivery_page_InnerHeading {
    color: rgb(97, 188, 71);
    font-weight: 700;
}

.Select_delivery_location_delivery_name {
    /* background-color: white;
    border-radius: 30px;
    margin:0;
    padding: 7px 40px;
    font-weight: 500; */
    background-color: white;
    border-radius: 30px !important;
    margin: 0;
    padding: 7px 0px;
    font-weight: 500;
    max-width: 105px;
    width: 105px;
    text-align: center;
}

.delivery_active {
    background-color: #EE0000 !important;
    color: white !important;
}

.delivery_location_page_content_heading {
    color: #EE0000 !important;
    margin-bottom: 15px !important;
    margin-top: 15px !important;
    padding-left: 12px;
}

.Select_delivery_page_inner_wrapper .Mui-selected {
    background-color: var(--red) !important;
    color: white !important;
    border-radius: 30px
}

.Single_btn_select_delivery_location {
    margin: 0px !important;
    border-radius: 30px !important;
    margin-right: 15px !important;
}

.Single_btn_select_delivery_location span {
    font-family: 'Poppins' !important;
    font-weight: bold;
    font-size: 15px;
    transition: none !important;
}

.Select_delivery_locations_list_wrapper {
    display: flex;
    flex-wrap: wrap;
    /* margin-top:10px; */
    font-size: 18px;
    column-gap: 10px;
    row-gap: 10px;
}

.Select_delivery_location_delivery_name:hover {
    background-color: rgb(97, 188, 71) !important;
    color: white !important;
}

/* =====================================End========================================================================================================================================================================================================================================================== */
/* ==============================AdminDashboard===================================================================================================================================================================================================================================== */
.Dashboard-page-tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(21, 64, 129);
    color: white;
    padding: 3px 19px;
    border-radius: 10px 10px 0px 0px;
}

.Dashboard-page-tab-header-div-two {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Dashboard-page-tab-header-div-two div {
    margin: 12px;
}

.Dashboard-page-tab-header-div-one h3 {
    margin: 0px;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
}

.Dashboard-page-tab-header-div-two h3 {
    font-size: 19px;
}

.Dashboard-page-tab-header-div-two p {
    font-size: 11px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
}

.Dashboard-page-tab-header-div-one h3, .Dashboard-page-tab-header-div-two p, .Dashboard-page-tab-header-div-two h3 {
    margin: 0px;
}

.AdminDashboard-whole-Main-wrapper {
    padding: 50px;
}

.AdminDashboard-tabs-left-side {
    width: 35%;
    background: #fff;
    margin: 10px;
}

.AdminDashboard-tabs-left-side .css-8je8zh-MuiTouchRipple-root {
    display: none;
}

.AdminDashboard-tabs-right-side {
    width: 60%;
    background: #fff;
    margin: 10px;
}

.dashboard-order-list-wrapper-inner-div-one, .dashboard-order-list-wrapper-inner-div-two {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3px;
    padding: 0px 16px;
}

.Inner-tab {
    width: 100% !important;
    display: block !important;
    padding: 0px !important;
    text-align: start !important;
    max-width: 100% !important;
    align-items: flex-start !important;
}

.Dashboard-page-seperator-line {
    position: relative;
    width: 70%;
}

.dashboard-order-list-wrapper {
    padding: 14px 0px;
    width: 100%;
    border-bottom: 3px solid rgb(242, 242, 242);
}

.Dashboard-page-seperator-line:before {
    content: '';
    background-color: rgb(210, 213, 205);
    color: rgb(210, 213, 205);
    position: absolute;
    width: 95%;
    height: 3px;
    left: 0px;
}

.Dashboard-page-seperator-line:after {
    content: '.';
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background: rgb(210, 213, 205);
    position: absolute;
    right: 8px;
    top: -3px;
}

.dashboard-order-list-wrapper-inner-div-one p {
    text-transform: capitalize;
    text-align: center;
    background: rgb(224, 232, 208);
    padding: 0px 14px;
    font-size: 12px;
    padding: 2px solid var(--red) 16px;
}

.dashboard-order-list-wrapper-inner-div-one h5 {
    color: rgb(21, 64, 129);
    font-size: 14px;
}

.dashboard-order-list-wrapper-inner-div-two p {
    width: 20%;
    text-transform: capitalize;
    font-weight: 600;
}

.dashboard-order-list-wrapper-inner-div-two-p-two {
    text-align: center;
}

.dashboard-order-list-wrapper-inner-div-two-p-one {
    text-align: start;
}

.AdminDashboard-tabs-left-side .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected .dashboard-order-list-wrapper {
    color: black !important;
    background: rgb(215 220 205);
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    overflow: visible !important;
}

.AdminDashboard-tabs-left-side .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected .Dashboard-page-seperator-line:before {
    content: '';
    background-color: black;
    color: rgb(210, 213, 205);
    position: absolute;
    width: 95%;
    height: 3px;
    left: 0px;
}

.AdminDashboard-tabs-left-side .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected .Dashboard-page-seperator-line:after {
    content: '.';
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background: black;
    position: absolute;
    right: 8px;
    top: -3px;
}

.dashboard-tab-inprogress {
    background-color: yellow !important;
    color: black;
}

.dashboard-tab-delivered {
    background-color: rgb(119, 162, 47) !important;
    color: #fff;
}

.dashboard-tab-cancel {
    background-color: rgb(244, 196, 196) !important;
    color: #000;
}

.AdminDashboard-tabs-right-side .css-19kzrtu {
    padding: 0px;
}

.AdminDashboard-tabs-rightside-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(21, 64, 129);
    color: white;
    padding: 23px 21px;
    border-radius: 10px 10px 0px 0px;
}

.rightsideheader-p {
    font-size: 13px;
    padding: 0px 17px;
    border-radius: 4px;
    font-weight: 500;
}

h5.rightsideheader-h5 {
    font-weight: 800;
    font-size: 17px;
}

h5.rightsideheader-h5 span {
    font-weight: 600;
    font-size: 13px;
}

.AdminDashboard-tabs-rightside-content {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.AdminDashboard-tabs-rightside-content-left-side {
    width: 70%;
}

.AdminDashboard-tabs-rightside-content-right-side {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-self: baseline;
}

.AdminDashboard-tabs-rightside-content-left-side-upper-div {
    border: 3px solid rgb(210, 213, 205);
    ;
    border-radius: 10px;
    padding: 20px;
    margin: 24px;
    position: relative;

}

.AdminDashboard-tabs-rightside-content-left-side-lower-div {}

.AdminDashboard-tabs-rightside-content-inner-divs {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px;
    margin-left: 0px;
}

.AdminDashboard-tabs-rightside-content-inner-divs:before {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 3px;
    content: ' ';
    margin: auto;
    border-bottom: 3px dashed #e3e3e3;
}

.AdminDashboard-tabs-rightside-content-inner-divs span {
    float: left;
    background: #FFF;
    position: relative;
    z-index: 999;
    font-weight: 700;
    font-size: 15px;
}

.AdminDashboard-tabs-rightside-content-inner-divs strong {
    float: right;
    background: #FFF;
    position: relative;
    z-index: 999;
    font-weight: 700;
    font-size: 15px;
}

.AdminDashboard-leftside-upper-div-p, .AdminDashboard-leftside-upper-div-h3 {
    text-align: left;
}

p.AdminDashboard-leftside-upper-div-p {
    font-size: 13px;
    font-weight: 500;
    margin: 0px !important;
    /* padding-left: 10px; */
}

h3.AdminDashboard-leftside-upper-div-h3 {
    color: rgb(35, 78, 147);
    font-weight: 700;
    font-size: 17px;
    /* padding-left: 10px; */
}

.AdminDashboard-tabs-rightside-content-dashline {
    border-bottom: 2px solid var(--red) dashed lightgrey;
    width: 75%;
}

.AdminDashboard-tabs-rightside-content-inner-divs-last {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 8px;
    margin-top: 12px;
    margin-left: 0px;
}

.AdminDashboard-tabs-rightside-content-inner-divs-last:before {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 3px;
    content: ' ';
    margin: auto;
    border-bottom: 3px dashed #e3e3e3;
}

.AdminDashboard-tabs-rightside-content-inner-divs-last span {
    float: left;
    background: #FFF;
    position: relative;
    z-index: 999;
    font-weight: 700;
    color: rgb(35, 78, 147);
    font-size: 15px;
}

.AdminDashboard-tabs-rightside-content-inner-divs-last strong {
    float: right;
    background: #FFF;
    position: relative;
    z-index: 999;
    font-weight: 700;
    color: rgb(35, 78, 147);
}

.AdminDashboard-tabs-rightside-content-inner-divs-last h6 {
    color: rgb(35, 78, 147);
    font-weight: 600;
    font-size: 14px;
}

.AdminDashboard-tabs-rightside-content-left-side-lower-div .AdminDashboard-leftside-upper-div-h3 {
    font-size: 15px;
    font-weight: 700;
}

.AdminDashboard-tabs-rightside-content-left-side-lower-div .AdminDashboard-tabs-rightside-content-inner-divs h6 {
    width: 44%;
}

.AdminDashboard-tabs-rightside-content-right-side-btn {
    background: linear-gradient(to right, rgb(150, 150, 150), rgb(109, 109, 109));
    color: white !important;
    font-weight: 600;
    text-transform: capitalize !important;
    margin: 15px !important;
    padding: 12px 21px !important;
    border-radius: 10px !important;
    width: 77%;
    margin: auto !important;
    margin-top: 22px !important;
}

.active-btndashboard {
    background: linear-gradient(to right, rgb(243, 121, 43), rgb(238, 45, 108)) !important;
}

.Dashboard-accept-order-button-wrapper {
    margin: 28px;
    margin-top: 0px;

}

.Dashboard-accept-order-button {
    background-color: rgb(119, 162, 47) !important;
    color: #fff !important;
    width: 80%;
    padding: 10px 11px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    border-radius: 10px !important;
}

.DesktopMain-wrapper .MainHeader-logo-div img {
    width: 24%;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.Inner-tab.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    position: relative;
}

/* .Mui-selected::after {
    content: '';
    width: 0;
    height: 0;
    right: -19px;
    top: 22px;
    border-top: 12px solid transparent;
    border-left: 20px solid rgb(215 220 205);
    border-bottom: 13px solid transparent;
    position: absolute;
} */

.Single-tab-wrapper-main .css-1cgx7kx-MuiTabs-scroller {
    overflow-y: unset !important;
    overflow-x: unset !important;
}

.Single-tab-wrapper-main .css-1numsu8-MuiTabs-root {
    overflow: unset !important;
}

.Dashboard-accept-order-button-wrapper-cancel {
    margin: 28px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Dashboard-accept-order-button-cancelrefund {
    background-color: rgb(227, 23, 76) !important;
    color: #fff !important;
    width: 60% !important;
    padding: 10px 11px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    border-radius: 10px !important;
    margin: 10px !important;
    margin-top: 0px !important;
}

.Dashboard-accept-order-button-cancel {
    background-color: rgb(204, 0, 1) !important;
    color: #fff !important;
    width: 60% !important;
    padding: 10px 11px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    border-radius: 10px !important;
    margin: 10px !important;
    margin-top: 0px !important;
}

.Dashboard-accept-order-button-none {
    background: linear-gradient(to right, rgb(150, 150, 150), rgb(109, 109, 109));
    color: white !important;
    font-weight: 600;
    text-transform: capitalize !important;
    margin: 15px !important;
    padding: 12px 21px !important;
    border-radius: 10px !important;
    width: 77%;
    margin-top: 22px !important;
}

.dashboard-tab-cancelled {
    background-color: rgb(244, 196, 196) !important;
}

.Header-login-logout-btn {
    cursor: pointer;
}

.right-icon-class-wrapper {
    position: absolute;
    right: -12px;
    top: -13px;
    background-color: #77b43f;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 2px solid var(--red) solid white;
}

.right-icon-class-wrapper svg {
    color: #fff;
    padding: 3px;
}

.wrong-icon-class-wrapper {
    position: absolute;
    right: -12px;
    top: -13px;
    background-color: rgb(204, 0, 1);
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 2px solid var(--red) solid white;
}

.wrong-icon-class-wrapper svg {
    color: #fff;
    padding: 3px;
}


/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================AdminStores===================================================================================================================================================================================================================================== */
/* .AdminStore-page-table-wrapper table {
    width: 100%;
    margin: auto;
    border-collapse: collapse;
}
.AdminStore-page-table-wrapper table thead{
background-color:rgb(233,238,244);
color:rgb(35,78,147);
}
.Stores-page-content-whole-wrapper {
    background: White !important;
    width: 92%;
    margin: auto !important;
    margin-top:5rem !important;
    margin-bottom:5rem !important;
}


.AdminStore-page-table-wrapper table thead th {
    padding: 11px 11px;
    font-size: 15px;
    text-align: left;
  
}
.AdminStore-page-table-wrapper table tbody tr{
    border-bottom:1px solid rgb(233,238,244);
    padding: 8px 18px !important;
}
.AdminStore-page-table-wrapper table tbody td{
    text-align: left ;
}
.Dashboard-page-tab-header.Stores-page-header{
    padding: 23px;
}
.Dashboard-page-tab-header.Stores-page-header h3 {
    font-size: 25px;
}
.Store-page-table-image-class {
    width: 50%;
    padding: 10px;
    border: 2px solid var(--red) solid rgb(233,238,244);
}
.StorePage-storeName-td{
    padding-left:0px;
    width: 40%;
}
.StorePage-storeName-td div h4 {
    font-weight: 900;
    font-size: 15px;
}
.StorePage-storeName-td div p {
    font-size: 13px;
}
.AdminStore-page-table-wrapper .css-qgjprd-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    height: 40px;
    margin-top: 6px;
}  
.AdminStore-page-table-wrapper span.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.PrivateSwitchBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase{
    right:0px !important;
    bottom:0px !important; 
}
.AdminStore-page-table-wrapper .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase{
 left:0px !important;
}
.AdminStore-page-table-wrapper .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    font-family: 'Poppins', sans-serif !important;
} */
/* ==============================End===================================================================================================================================================================================================================================== */

/* ===================HelpPage=============================================================================================================================================================================================================================================================== */
.Help-icon-wrapper {
    position: fixed;
    bottom: 0rem;
    right: 31rem;
    cursor: pointer;
}

.Help-icon-wrapper img {
    object-fit: cover;
    width: 70px;
    height: 70px;
}

.Help-page-Header-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 24px;
}

.Help-page-Header-inner-div-one {
    display: flex;
}

.Help-page-Header-div-h4 {
    font-weight: 500;
    font-size: 20px;
}

.Help-Page-Main-content-wrapper {
    height: 100vh;
    background-color: white;
}

.Help_page-content-p {
    font-size: 13px;
    font-family: 'Poppins', sans-serif !important;
    text-align: start;
    margin: 20px !important;
}

h3.Help_page-content-h3 {
    text-align: start;
    margin: 20px !important;
    font-weight: 600;
    font-size: 16px;
}

h6.Help_page-content-h6 {
    text-align: start;
    margin: 20px !important;
    font-size: 12px;
    font-weight: 500;
}

.Help_page_content_heading {
    font-size: 16px;
    margin-left: 20px !important;
    font-weight: 500;
}

/* ===================End=============================================================================================================================================================================================================================================================== */
/* ================OrderDelivered========================================================================================================================================================================================================================================================================= */
.OrderDelivered_page_thankYou_div_wrapper {
    background-color: var(--blue);
    height: 22vh;
    color: white;
    text-align: center;
}

.OrderDelivered_page_thankYou_div_wrapper h3 {
    font-size: 23px;
    padding-top: 24px;
    font-weight: bold;
}

.OrderDelivered_page_tick_div {
    background-color: white;
    border-radius: 100%;
    margin: auto;
    width: 120px;
    height: 120px;
    margin-top: -66px;
    display: flex;
    /* padding: 38px 1px; */
    justify-content: center;
    margin-bottom: 20px;
}

.OrderDelivered_page_tick_div img {
    width: 72px;
}

.OrderDelivered_page_h3 {
    color: var(--blue);
    font-weight: 800;
    font-size: 27px;
}

.OrderDelivered_page_content_div {
    text-align: center;
    padding-bottom: 20px;
}

.OrderDelivered_page_content_div h4 {
    font-weight: 800;
    font-size: 19px;
}

.OrderDelivered_page_content_div p {
    color: var(--blue);
    font-size: 20px;
    font-weight: 600;
}

.timeline_dot_red {
    background-color: var(--blue) !important;
    border-color: var(--blue) !important;
}

/* ====================End==================================================================================================================================================================================================================================================================================================== */
/* ======================OrderCancel====================================================================================================================================================================================================================================================================================== */
.OrderCancel_page_thankYou_div_wrapper {
    background-color: #DADADA;
    height: 22vh;
    color: black;
    text-align: center;
}

.OrderCancel_page_thankYou_div_wrapper h3 {
    font-size: 23px;
    color: black !important;
    padding-top: 24px;
    font-weight: bold;
}

.OrderCancel_page_h3 {
    color: black;
    opacity: 0.8;
    font-size: 26px;
    font-weight: bold;
}

.OrderCancel_page_content_div h4 {
    font-weight: 800;
    font-size: 19px;
    color: black;
    opacity: 0.8;
}

.OrderCancel_page_content_div p {
    color: black;
    opacity: 0.8;
    font-size: 20px;
    font-weight: 600;
}

.timeline_dot_grey {
    background-color: rgb(230, 230, 230) !important;
    border-color: rgb(230, 230, 230) !important;
}

.OrderCancel_page_content_div {
    padding-bottom: 20px;
}

.CancelOrder_page_refund_p_tag {
    color: var(--red);
    font-weight: 600;
    width: 85%;
    margin: auto;
}

/* ============================End=============================================================================================================================================================================================================================================================================================== */
/* ===================MoreDetails=============================================================================================================================================================================================================================================================== */
.MoreDetials_page_Banner_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 37px;
}

.MoreDetials_page_Banner_wrapper img {
    width: 35%;
}

.More_Details_Page_h2 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0px !important;
    text-align: left;
    padding: 20px;
    padding-bottom: 0px !important;
}

.More_Details_Page_h5 {
    font-weight: 700;
    color: rgb(49 96 145);
    line-height: 19px;
    margin-bottom: 0px;
    font-size: 13.9px;
    text-align: start;
}

.More-details-page-content-p {
    line-height: 20px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    text-align: start;
}

.ItemListMain-Wrapper h2 {
    font-size: 20px;
    text-align: left;
}

/* ===================End=============================================================================================================================================================================================================================================================== */

/* ==============================Responsive===================================================================================================================================================================================================================================== */
@media only screen and (max-width: 1024px) {
    .MainHeader-wrapper {
        padding: 2px 2px 2px;
    }

    .AdminDashboard-tabs-rightside-content {
        display: block !important;
    }

    .AdminDashboard-tabs-rightside-content-left-side {
        width: 100%;
    }

    .AdminDashboard-tabs-rightside-content-right-side {
        width: 100%;
    }

    .AdminDashboard-whole-Main-wrapper {
        padding: 50px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .AdminDashboard-tabs-left-side {
        width: 50%;
        background: #fff;
        margin: 10px;
    }

    .AdminDashboard-tabs-right-side {
        width: 50%;
        background: #fff;
        margin: 10px;
    }

    .AdminDashboard-tabs-rightside-content {
        padding: 0px;
    }

    .Dashboard-accept-order-button {
        width: 100%;
    }

    .Dashboard-accept-order-button-wrapper {
        margin: 28px;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 992px) {
    .StorePage-storeName-td div p {
        font-size: 11px;
    }

    .StorePage-storeName-td div h4 {
        font-size: 13px;
    }

    .Congratulations_page_estimated_time_div {
        width: 100%;
    }

    .Store-page-table-image-class {
        width: 75%;
    }

    .AdminStore-page-table-wrapper .css-qgjprd-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        height: 36px;
        width: 160px !important;
    }

    .AdminStore-page-table-wrapper .css-j204z7-MuiFormControlLabel-root {
        margin-left: -2px solid var(--red);
    }

    .MainHeader-logo-div p {
        top: 23px;
    }

    .MainHeader-logo-div img {
        width: 170px;
    }

}

@media only screen and (max-width: 767px) {
    .AdminDashboard-whole-Main-wrapper-div-one .MuiBox-root.css-orkwbp {
        display: block;
    }

    .AdminDashboard-tabs-left-side {
        width: 100%;
        margin: -6px;
    }

    .AdminDashboard-tabs-right-side {
        width: 100%;
        margin: -6px;
    }

    .MainHeader-logo-div-wrapper {
        width: 100%;
    }

}

@media screen and (max-width:600px) {
    .MainWhole-wrapper-container-2 {
        width: 100% !important;
    }

    body {
        overflow-x: hidden !important;
    }

    .MainWhole-wrapper-container {
        width: 100% !important;
        /* height: 100vh; */
    }

    .ItemList_Page_Next_button {
        width: 100% !important;
        left: 0 !important;
        right: unset !important;
    }



    .CartPage-Main-wrapper .DetailsPage-content-wrapper-inner:nth-child(1) {
        display: block !important;
    }

    .CartPage-Main-wrapper .ItemsListPage-ItemsList-Counter-wrapper {
        margin-left: 0rem;
    }

    .Congratulation-content-div-one-wrapper-inner-two h1 {
        font-size: 26px;
    }

    .Congratulation-content-div-one-wrapper-inner-two p {
        margin: 0px;
        font-weight: normal;
        width: 77% !important;
        color: var(--black);
        font-weight: bold;
    }

    /* .Congratulation-content-div-one-wrapper-inner-one img {
            width: 74%;
   }     */

    .HomePage_slider_div-class {
        background-size: 182px !important;
        margin-top: 15px;
    }

    h1.HomePage-Main-Heading-text {
        width: 90%;
        margin: auto !important;
        font-size: 27px;
        line-height: 28px;
    }

    .HomePage_slider_content_div_content h4 {
        margin-top: 20px !important;
        font-size: 20px;
        width: 66%;
        line-height: 24px;
    }

    .HomePage_slider_content_div_content {
        margin-bottom: 0rem;
        margin-left: 0px;
        width: 100%;
        text-align: center;
    }

    .owl-carousel .owl-stage-outer {
        margin-bottom: 0px;
    }

    .owl-theme .owl-nav {
        top: 20px;
    }

    .HomePage-Main-Wrapper-Container .owl-dots {
        margin-top: 15px;
    }

    .HomePage_order_now_button {

        padding: 10px 45px;
        color: white;
        border: none !important;
        border-radius: 50pc;
        font-size: 28px;
        font-weight: 600;
        margin: 20px 10px !important;
        cursor: pointer;
        position: relative;
        z-index: 99999999 !important;
        margin-top: 25px !important;
    }
}

/* ==============================End===================================================================================================================================================================================================================================== */
.react-tel-input .country-list .country {
    position: relative;
    padding: 12px 9px 13px 46px;
    text-align: justify;
}

@media screen and (max-width:420px) {
    .HomePage_slider_content_div_content h4 {
        width: 100%;
    }
}

@keyframes example {
    0% {
        transition: 1s ease-in-out;
        box-shadow: 0px 0px 0px 3px rgb(253, 243, 45);
        border-radius: 100%;
    }

    50% {
        transition: 1s ease-in-out;
        box-shadow: 0px 0px 0px 3px rgb(253, 243, 45);
        border-radius: 100%;
    }

    100% {
        border: none;
        transition: 1s ease-in-out;
        border-radius: 100%;
    }
}

.gm-style div:nth-child(2) div:nth-child(2) div div:nth-child(3) div {
    position: relative;
    animation-name: example;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    border-radius: 100%;
}

.Congratulation-Main-wrapper {
    overflow: auto;
    /* margin-bottom: 5rem; */
    height: calc(100vh - 11rem);
}

.MainHeader-logo-div-wrapper {
    height: 5rem;
}

.mapboxgl-ctrl-bottom-left {
    display: none;
}

.mapboxgl-ctrl-bottom-right {
    display: none;
}